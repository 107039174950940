.contact.values {
  margin: 0;
  padding: 0 50px;
}

@media only screen and (max-width: 600px) {
  .contact.values {
    padding: 0 25px;
  }
}
