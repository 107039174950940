.section {
  min-height: 500px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  vertical-align: middle;
  gap: 55px;
}

.sectionTitle {
  width: calc(100% - 100px);

  margin: 0;
  padding: 55px 50px 0px 50px;

  color: var(--primary, #1e1e1e);
  text-align: left;
  font-family: "neuzeit-grotesk", sans-serif;
  font-size: 3em;
  font-style: normal;
  line-height: 100%;
}

.sectionSpacer {
  margin: 75px 0;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

@media only screen and (max-width: 600px) {
  .section {
    gap: 35px;
  }

  .sectionTitle {
    width: calc(100% - 50px);

    margin: 0;
    padding: 35px 25px 0px 25px;

    font-size: 2.5em;
  }

  .sectionSpacer {
    margin: 50px 0;
  }
}
