html {
  margin: 0;
  padding: 0;

  width: 100%;
  min-height: 100%;
  height: auto;

  overflow-x: hidden;

  background-color: hsl(33deg 56% 93%);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    180deg,
    hsl(33deg 56% 93%) 0%,
    hsl(32deg 43% 93%) 16%,
    hsl(32deg 32% 92%) 31%,
    hsl(31deg 22% 92%) 44%,
    hsl(31deg 13% 91%) 55%,
    hsl(30deg 4% 91%) 65%,
    hsl(30deg 4% 92%) 74%,
    hsl(30deg 4% 94%) 82%,
    hsl(30deg 4% 96%) 89%,
    hsl(30deg 3% 97%) 95%,
    hsl(0deg 0% 99%) 100%
  );
}

body {
  margin: 0;
  width: 100%;
  height: 100%;

  font-family: "neuzeit-grotesk", sans-serif;
}

/* Variables */
html {
  --header-height: 120px;
}

/* Hide Scrollbar */
html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
