.footer {
  padding: 40px 50px 10px 50px;
  text-align: center;

  color: #949494;
  font-size: 1em;
  line-height: 1.5em;
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 10px 25px;
    font-size: 0.8em;
  }
}
