.Copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Title {
  margin: 0;

  color: var(--primary, #1e1e1e);
  text-align: center;
  font-family: "neuzeit-grotesk", sans-serif;
  font-size: 9em;
  font-style: normal;
  line-height: 100%;
}

.Profile {
  width: 65%;

  color: var(--primary, #1e1e1e);
  text-align: center;
  font-family: "neuzeit-grotesk", sans-serif;
  font-size: 1.75em;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@media only screen and (max-width: 600px) {
  .Title {
    font-size: 5em;
  }

  .Profile {
    width: 100%;
    font-size: 1.3em;
  }
}
